.centered-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.centered-image {
    align-self: center;
    max-width: 30%;
    height: auto;
}
